import { Link } from "gatsby";
import React from "react";

const Cta = () => {
  return (
    <div className="bg-gray-100">
      <div className="flex items-center flex-col md:flex-row justify-between max-w-xl mx-auto px-4 sm:px-6 md:max-w-3xl lg:max-w-7xl lg:px-8 py-16 ">
        <div>
          <h1 className="text-3xl md:text-4xl text-center md:text-left mb-4 font-bold">
            Vill du veta mer?
          </h1>
          <p className="text-base md:text-lg mb-4 md:mb-6 max-w-lg">
            Tveka inte att fråga, vi finns här för dig.{" "}
          </p>
          <a
            href="tel:036141500"
            className="underline text-center mb-6 md:mb-0 md:text-left block"
          >
            Kontakta oss
          </a>
        </div>
        <Link
          to="/booking"
          className="px-9 py-3 border border-transparent text-base font-medium rounded-full text-white bg-indigo-600 transition-opacity hover:opacity-80"
        >
          Beräkna ditt pris
        </Link>
      </div>
    </div>
  );
};

export default Cta;
