import React from "react";
import "./AboutUsContent.scss";
import LikeIcon from "../../assets/images/icons/like.svg";
import AboutBaner from "../AboutBaner/AboutBaner";

//
const AboutUsContent = () => {
  return (
    <>
      <div className="AboutUsContent">
        <div className="top-section">
          <h3>VÅR VISION</h3>
          <h1>Att skapa en enklare vardag för människor. </h1>
          <h3>
            Vår affärsidé går ut på att vara en personlig fönsterputsfirma och
            erbjuda högsta kvalitet av service till låga priser.
          </h3>
        </div>
        <div className="bg-gray-50 about-us-section">
          <h2 className="font-bold text-main-600">
            Nöjda fönsterputsare = Nöjda kunder
          </h2>
          <p className="text-main-600">
            Med olika nationaliteter från världens alla hörn. Putsos
            professionella fönsterputsare är en grupp fantastiskt talangfulla
            människor från hela världen som alla strävar efter att skapa en
            enklare vardag med guldkant!
          </p>
        </div>
        <div className="about-us-section">
          <h2 className="font-bold text-main-600">
            Våra grundläggande värderingar
          </h2>
          <p className="text-main-600">
            Våra grundläggande värderingar formar vårt företagsklimat och
            vägleder oss när vi fattar beslut. De finns alltid i våra tankar och
            handlingar och skapar en gemenskap på där människor från olika
            länder, kulturer och med olika bakgrunder bemöter varandra med
            ömsesidig respekt och arbetar tillsammans.
          </p>
          <div className="hearts">
            <div className="flex">
              <div>
                <LikeIcon />
              </div>
              <div>
                <h4 className="font-semibold text-main-600">Ansvar</h4>
                <p className="text-main-600">
                  Vi tar ansvar för resultaten av våra beslut. Vi sätter och
                  uppnår hög standard.
                </p>
              </div>
            </div>
            <div className="flex">
              <div>
                <LikeIcon />
              </div>
              <div>
                <h4 className="font-semibold text-main-600">Integritet</h4>
                <p className="text-main-600">
                  Vi är ärliga, moraliska och rättvisa.
                </p>
              </div>
            </div>
            <div className="flex">
              <div>
                <LikeIcon />
              </div>
              <div>
                <h4 className="font-semibold text-main-600">Respekt</h4>
                <p className="text-main-600">
                  Vi respekterar andra, varandra och gör vårt bästa för att
                  bygga ömsesidigt förtoende.
                </p>
              </div>
            </div>
            <div className="flex">
              <div>
                <LikeIcon />
              </div>
              <div>
                <h4 className="font-semibold text-main-600">Kvalitet</h4>
                <p className="text-main-600">
                  Vi levererar alltid de bästa i allt vi gör, Vi strävar
                  ständigt efter att bli bättre.
                </p>
              </div>
            </div>
            <div className="flex">
              <div>
                <LikeIcon />
              </div>
              <div>
                <h4 className="font-semibold text-main-600">Teamwork</h4>
                <p className="text-main-600">
                  Vi respekterar och litar på varandra för att nå goda resultat.
                  Vi trivs med att arbeta tillsammans och stödjer varandra mot
                  samma vision: Att skapa en enklare vardag för människor.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <AboutBaner />
    </>
  );
};

export default AboutUsContent;
