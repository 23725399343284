import React from "react";
import baner from "../../assets/images/about-baner.png";
import "../BanerSection/BanerSection.scss";

const AboutBaner = () => {

	return (
		<div className="BanerSection">
			<div className="baner-content items-end">
				<h1 className="max-w-lg text-main-600">Med omtanke för vårt gemensamma hem</h1>
				<p className="max-w-lg text-main-600">
					Vi värdesätter miljön och strävar efter att agera så ansvarsfullt som möjligt när det kommer till alla steg i vår putstjänst. Det är därför vi tar med miljövänliga rengöringsmedel när vi putsar dina fönster - utan att kompromissa med kvaliteten.
				</p>
			</div>
			<img src={baner} alt="Baner" />
		</div>
	);
};

export default AboutBaner;
